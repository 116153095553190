const navigationElements = document.querySelector<HTMLElement>('[data-navigation-sticky]')

if (navigationElements) {
    const navigation = document.querySelector<HTMLElement>('.navigation')!
    let isScrolled: boolean | null = null

    window.addEventListener('scroll', () => {
        const newIsScrolled = window.scrollY > 0

        if (newIsScrolled !== isScrolled) {
            isScrolled = newIsScrolled
            navigation.classList.toggle('page-scrolled', isScrolled)
        }
    })
}

function setActiveNavItem(element: HTMLElement): void {
    const navigationLinks = document.querySelectorAll('.nav-link')

    navigationLinks.forEach((link) => link.classList.remove('active'))

    element.classList.add('active')
}

document.querySelectorAll('.nav-link').forEach((link) => {
    link.addEventListener('click', (event) => setActiveNavItem(event.target as HTMLElement))
})

if (location.hash) {
    const el = document.getElementById(`${location.hash.slice(1)}-nav`)

    if (el) {
        setActiveNavItem(el)
    }
}
